import React, { useEffect } from 'react';
import './App.css';
import { Button } from 'react-bootstrap';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Components from './components';
import logo from './dove.png';
//import { useNavigate } from 'react-router-dom';
import { fetchAuthSession } from 'aws-amplify/auth';

export default function App() {
  const { Header, Footer } = Components();
  //const navigate = useNavigate();
  const { user } = useAuthenticator((context) => [context.user]);

  useEffect(() => {
    const storeToken = async () => {
      if (user) {
        try {
            const session = await fetchAuthSession();
            const idToken = session.tokens?.idToken;
            if (idToken) {
              document.cookie = `jwtToken=${idToken}; Secure; HttpOnly; SameSite=Strict; Path=/`;
            } else {
              console.error('ID token is undefined');
            }
          } catch (error) {
            console.error('Error fetching auth session', error);
          }
      }
    };

    storeToken();
  }, [user]);

  const formFields = {
    signIn: {
        'username': {
        placeholder: 'Enter your email Please',
        },
    },
    signUp: {
        password: {
        label: 'Password:',
        placeholder: 'Enter your Password:',
        isRequired: false,
        order: 2,
        },
        confirm_password: {
        label: 'Confirm Password:',
        order: 1,
        },
    },
    forceNewPassword: {
        password: {
        placeholder: 'Enter your Password:',
        },
    },
    forgotPassword: {
        username: {
        placeholder: 'Enter your email please:',
        },
    },
    confirmResetPassword: {
        confirmation_code: {
        placeholder: 'Enter your Confirmation Code:',
        label: 'New Label',
        isRequired: false,
        },
        confirm_password: {
        placeholder: 'Enter your Password Please:',
        },
    },
    setupTotp: {
        QR: {
        totpIssuer: 'test issuer',
        totpUsername: 'amplify_qr_test_user',
        },
        confirmation_code: {
        label: 'New Label',
        placeholder: 'Enter your Confirmation Code:',
        isRequired: false,
        },
    },
    confirmSignIn: {
        confirmation_code: {
        label: 'New Label',
        placeholder: 'Enter your Confirmation Code:',
        isRequired: false,
        },
    },
  };

  const handleRedirectWithToken = (url: string) => {
    const token = localStorage.getItem('jwtToken'); // Retrieve the JWT token from local storage
    window.location.href = `${url}?token=${token}`; // Redirect to the external URL with the token as a query parameter
  };

  const handleRedirectWithoutToken = (url: string) => {
    //window.location.href = url; // Redirect to the external URL without the token
    window.location.replace(url); // Use replace to ensure a full page redirect
  };

  const handleSignOut = (signOut:any) => {
    localStorage.removeItem('jwtToken');
    signOut();
  };

  return (
    <div className="App">
      <header className="App-header">
      <img src={logo} className="App-logo" alt="logo" />
      <Header />
      <Authenticator formFields={formFields}>
        {({ signOut, user }) => (
            <div className="links-container">
                <div className="button-container">
                    <h1>Welcome</h1>
                    <Button className="custom-button" onClick={() => handleRedirectWithoutToken('https://stringfeeds.com/jobs/')}>Jobs</Button>
                    <Button className="custom-button" onClick={() => handleRedirectWithoutToken('https://stringfeeds.com/tanach/')}>Bible</Button>
                    &nbsp;<button className="btn btn-danger" onClick={signOut}>Sign Out</button>
                    {/* <Link className="App-link" to="/tanach/HomeJudat">Judaism</Link> */}
                </div>

                
            </div>
            )
        }
      </Authenticator>
      <Footer />
      </header>
    </div>
  );
}

