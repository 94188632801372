import { View, Text, Heading, Button, useAuthenticator, useTheme } from '@aws-amplify/ui-react';
//import dove from './dove.png'; // Assuming this is the correct path

export default function Components() {
  const { tokens } = useTheme();

  const components = {
    Header() {
      return (
        <View textAlign="center" padding={tokens.space.large}>
        </View>
      );
    },

    Footer() {
      const currentYear = new Date().getFullYear();
      const copyrightStyle = {
        color: '#e1ecef',fontSize: 16
      };
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Text style={copyrightStyle}>
            &copy; {currentYear} StringFeed, LLC.
          </Text>
        </View>
      );
    },

    SignIn: {
      Header() {
        return (
          <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
            Sign in to your account
          </Heading>
        );
      },
      Footer() {
        const { toForgotPassword } = useAuthenticator();
        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toForgotPassword}
              size="small"
              variation="link"
            >
              Reset Password
            </Button>
          </View>
        );
      },
    },

    SignUp: {
      Header() {
        return (
          <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
            Create a new account
          </Heading>
        );
      },
      Footer() {
        const { toSignIn } = useAuthenticator();
        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toSignIn}
              size="small"
              variation="link"
            >
              Back to Sign In
            </Button>
          </View>
        );
      },
    },

    ConfirmSignUp: {
      Header() {
        return (
          <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      },
    },

    SetupTotp: {
      Header() {
        return (
          <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      },
    },

    ConfirmSignIn: {
      Header() {
        return (
          <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      },
    },

    ForgotPassword: {
      Header() {
        return (
          <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      },
    },

    ConfirmResetPassword: {
      Header() {
        return (
          <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      },
    },
  };
  return components;
}  
